<template>
    <div class="module h-full flex px-4 py-0">
        <pipeline class="w-1/3" :opportunity="true" />
        <separator :direction="'vertical'"/>
        <opportunities-list />
    </div>
</template>

<script>

const pipeline = () => import('@/components/Desktop/Pipeline.vue');
const OpportunitiesList = () => import('@/components/Desktop/Pipeline/OpportunitiesList.vue');

export default {
    components:{
        pipeline,
        OpportunitiesList
    }
}
</script>